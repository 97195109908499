.cust{
    height: 70vh;
}

.nav-logo{
    width: 3vw;
    margin-right: 10px;

}

@media (max-width: 992px){
    .nav-logo {
        width: 7vw;
    }
}

.custom-bgimg{
    width: 100%;
    height: 70vh;
}


.stikkkk{
    position: -webkit-sticky !important;
    z-index: 10;
  position: sticky !important;
  top: 0;
  padding: 5px;
  background-color: #cae8ca;
}

.container {
    height: 100%;
    width: 100%;
  }

.cust-size{
    /* width: 50% !important;  */
    height: 50% !important;
}

.card-margin{
    padding: 50px;
    margin-right: 0 !important;
}

.card-custom{
    align-items: center;

}
.custom-image{
    /* width: 80% !important; */
    height: 50vh;
}

.custom-body{
    background-color: var(--primary-color);
    color: var(--primary-font-color) !important;
}

.Aboutfield{
    display: flex;
    flex-wrap: wrap;
    padding: 50px;
    gap: 20px;
    justify-content: space-around;
    
}
.aboutCompany{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}

.aboutUs-content{
    font-weight: bold;
}
.bookAppointment{
    /* display: flex;
    flex-direction: column; */
    /* width: 40%; */
    flex: 1;
    background-color: var(--secondary-color);
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
}

.custome-logo{
    width: 70%;
}

.carasoule-heading{
    font-weight: bold;
}

.whyusfield{
    display: flex;
    flex-direction: column;
    background-color: antiquewhite;
    padding: 50px;
    margin: 50px;
}

.list-align{
    display: flex;
    justify-content: center;
}

.innner-carasoule{
    width: 70vw;
}

.list-align>ul>li{
    text-align: initial;
}

.centersection{
    display: flex;
    align-items: center;
    justify-content: center;
}

/* CopyrightFooter.css */

.footer {
    background-color: #f2f2f2;
    padding: 20px;
    text-align: center;
  }
  
  .footer p {
    margin: 0;
    font-size: 14px;
    color: #888;
  }
  
  .footer p a {
    color: #888;
    text-decoration: none;
  }
  
  .footer p a:hover {
    text-decoration: underline;
  }
  

.modal-custom{
    background-color: rgba(0,0,0,0.7);
}

.modal-body{
    background-color: var(--secondary-color);
    color: var(--secondary-font-color);
}