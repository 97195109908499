.services-outer-wrapper {
  display: inline-grid;
  /* grid-auto-flow: row; */
  grid-template-columns: repeat(3, minmax(376px, 1fr));
  width: 100%;
  /* grid-template-rows: 1fr 1fr 1fr; */
}
@media (max-width: 1200px) {
  .services-outer-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(376px, 1fr));
  }
}
