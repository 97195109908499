.about-section {
  display: flex;
}
.about-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 115px;
  padding: 5% 10%;
  width: 100%;
}
.about-text-container {
  color: #000;
  /* font-family: "Hind"; */
  font-size: 24px;
  /* font-style: normal; */
  font-weight: 500;
  /* line-height: normal; */
  display: flex;
  text-align: justify;
  flex-direction: column;
  width: 50%;
  max-width: 800px;
  gap: 50px;
  /* word-break: break-all; */
}

.about-image-container img {
  max-width: 40vw;
}

.about-secondary-content {
  color: #000;
  /* font-family: Hind; */
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: justify;
}

.about-image-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.highlighted-text-span {
  color: #ff8b5d;
}

@media (max-width: 1400px) {
  .about-container {
    flex-direction: column;
  }
  .about-text-container {
    width: 100%;
    max-width: 100%;
  }
  .about-secondary-content {
    flex-direction: row;
    gap: 50px;
  }
}

@media (max-width: 800px) {
  .about-secondary-content {
    flex-direction: column;
    gap: 20px;
  }
}
