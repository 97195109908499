.social-container {
  background: url("../assets/new/contact-bg.png"),
    rgb(64, 63, 63) -43.091px -251.873px / 104.519% 213.563% no-repeat;
  padding: 25px 50px 50px;
  height: 50vh;
  display: flex;
  align-items: end;
  justify-content: center;
  color: white;
}
.social-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px;
  width: 100%;
  max-width: 600px;
}
.social-icon-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  row-gap: 20px;
}
a.social {
  margin: 0 1rem;
  /* transition: transform 250ms; */
  display: inline-block;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

a.social:hover {
  /* transform: translateY(-2px); */
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
}

a.social {
  color: #fee2c3;
}

.callus {
  padding-top: 10px;
  font-weight: bold;
  font-size: 2em;
  color: var(--primary-color);
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-6px);
    -ms-transform: translateY(-6px);
    transform: translateY(-6px);
  }
}

@media (max-width: 600px) {
  .social-content-wrapper {
    margin: 30px 0;
  }
}
