.card-item-wrapper {
  padding: 50px;
  /* background-color: #; */
  /* max-width: 576px; */
  color: white;
  min-height: 426px;
  max-height: 60vh;
}
.card-item__content-wrapper {
  border: 5px solid #fee2c3;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(193, 86, 76, 0) 0%,
    rgba(193, 86, 76, 0.8) 100%
  );
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  min-height: 326px;
  /* height: 376px; */
}
.card-item__title {
  color: #fff;
  text-align: center;
  font-family: Hind;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.1%;
  letter-spacing: -0.36px;
}
.card-item__button {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  border: 2px solid #f5f5f5;
  background: transparent;
  color: white;
}
