.navbar-outer-wrapper {
  position: -webkit-sticky !important;
  z-index: 10;
  position: sticky !important;
  top: 0;
  padding: 5px;
  background-color: #cae8ca;
  width: 100%;
  min-height: 80px;
}
.navbar-container {
  justify-content: space-between;
  margin: 0 10px;
  width: 100%;
}
.navlink-container {
  justify-content: end;
}
.me-auto {
  margin: 0 !important;
}

.me-auto a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 1.1rem;
}

@media (min-width: 1500px) {
  .navbar-container {
    max-width: 100%;
  }
}
@media (max-width: 1500px) {
  .navbar-container {
    max-width: 100%;
  }
}
