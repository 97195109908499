.connect-card-wrapper {
  max-width: 690px;
  background: #021938;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.connect-card-wrapper h1 {
  color: #fee2c3;
  text-align: center;
  font-family: Hind;
  /* font-size: 10vw; */
  font-style: normal;
  /* font-weight: 600; */
  line-height: normal;
  margin: 0;
}
.connect-card-wrapper h3 {
  font-size: 22px;
}

.connect-card-wrapper p {
  color: #fee2c3;
  text-align: center;
  font-family: Hind;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.connect-card__logo-wrapper {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.connect-card__logo-wrapper img {
  width: 60px;
  height: 60px;
}

.connect-card__booking-button {
  color: #021938;
  max-width: 150px;
  border-radius: 10px;
  background: #fee2c3;
  border: none;
  transition: 100ms ease-in-out;

  /* padding: 10px 50px; */
}
.connect-card__booking-button:hover {
  color: #021938;
  max-width: 150px;
  border-radius: 10px;
  background: #fee2c3;
  border: none;
  transform: scale(1.05);
  transition: 100ms ease-in-out;
}

.connect-footer-wrapper {
  display: flex;
  width: 100%;
}
.connect-footer-wrapper iframe {
  /* flex-grow: 1; */
  min-height: 580px;
  width: 100%;
}
.connect-card__social-wrapper {
  width: 100%;
  margin-top: 10px;
}

@media (max-width: 700px) {
  .connect-footer-wrapper {
    flex-direction: column;
  }
}
