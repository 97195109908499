.testimonials {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15vh 0;
  gap: 30px;
  width: 100%;
}

.testimonial {
  /* margin: 0 auto; */
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 10px;
  border: 2px solid rgba(33, 37, 41, 0.27);
}

.testimonial-slider {
  max-width: 600px;
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.testimonial-slider button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-content {
  font-size: 18px;
  margin-bottom: 10px;
}

.testimonial-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.testimonial-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.testimonial-role {
  color: #888;
}

.testimonial-rating {
  margin-top: 10px;
}

.testimonial-stars {
  display: flex;
  align-items: center;
}

.star {
  color: #888;
  margin-right: 4px;
}

.star.filled {
  color: #fbd600;
}

/* Carousel Styles */

.carousel-root {
  margin-top: 20px;
}

.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f1f1f1;
}

.carousel .slide .testimonial {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel .slide .testimonial-content {
  margin-bottom: 15px;
}

.carousel .slide .testimonial-info {
  margin-bottom: 10px;
}

.carousel .slide .testimonial-rating {
  margin-top: 15px;
}

.carousel .control-dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .control-dots .dot {
  width: 10px;
  height: 10px;
  background-color: #888;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.carousel .control-dots .dot.selected {
  background-color: #333;
}

.slider-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.slider-btn {
  width: 40px;
  height: 200px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;
}

.slider-btn:hover {
  color: #555;
}

.prev-btn {
  margin-left: 10px;
}

.next-btn {
  margin-right: 10px;
}
