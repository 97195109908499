.appointment-form-wrapper {
  width: 90%;
  display: flex;
  max-width: 500px;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  border-radius: 10px;
  background: rgba(255, 241, 222, 0.83);
  position: absolute;
  bottom: 5%;
  right: 5%;
  min-width: 300px;
  scroll-snap-align: center;
}
.appointment-form__input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  background: transparent;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
}
.appointment-form__input:focus {
  border: none;
  background-color: transparent;
  box-shadow: none;
  border-bottom: 1px solid;
}

.appointment-form__button {
  display: flex;
  width: 123px;
  height: 33px;
  padding: 5px 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background: #021938;
  border: none;
  transition: 100ms ease-in-out;
  margin: 0 auto;
}

.appointment-form__button:hover {
  background: #021938;
  transform: scale(1.1);
  transition: 100ms ease-in-out;
  border: none;
}

@media (max-width: 990px) {
  .appointment-form-wrapper {
    position: relative;
    right: auto;
  }
}
